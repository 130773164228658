body.light{
    color: #242424;
    fill: #242424;
    background-color: white;
}

.light .backgroundColor{
    background-color: white;
}

.light .accentColor{
    color: #0091B7;
    fill: #0091B7;
}

.light .inputBorder{
    border-color: #707070;
}

.light textarea{
    color: #242424;
    background-color: white;
}

.light .bookBar{
    border: solid 1px darkgrey;
    background-color: white;
}
.light .bookBarProgress{
    background-color: #0091B7;
}

.light .singleDay{
    border-color: #707070;
}

.light .singleDay.active{
    border-color: #05b703 !important;
}
.light .singleDay.done{
    background-color: #0091B7;
}
.light .singleDay.today{
    border-color: #242424;
}
.light .singleDay.future{
    background-color: white;
    border-color:#d0d0d0;
}
.light .singleDay.futureMonth{
    background-color: lightgrey;
    border-color:#d0d0d0;
}

.light .hasEntry{
    background-color: #0091B7;
}
.light .hasComment{
    background: linear-gradient(135deg, transparent 0%, transparent 50%, #00c7fc 51%, #00c7fc);
}
.light .hasEntry.hasComment{
    background: linear-gradient(135deg, #0091B7 0%, #0091B7 50%, #00c7fc 51%, #00c7fc);
}

.light .graphicColor1{
    fill: #0091B7;
}

.light .graphicColor2{
    fill: #00c7fc;
}

.light .error{
    color: red;
}

.light .message{
    color: #4682b4;
}