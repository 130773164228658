body{
  position: relative;
  font-family:Arial;
  padding: 0;
  margin: 0;
}
.container{
  margin: auto;
  max-width: 1000px;
  padding: 10px 5px 5px 5px;
}

.backer{
  /*Make this fill the whole background, so anywhere can be clickable.*/
  z-index: -1;
  position: fixed;
  height: 100%;
  width: 100%;
}

.hidden{
  display: none;
}

input {
  outline:none;
  border-color: dimgrey;
  border-style: solid;
  border-width: 1px;
  margin: 5px;
  padding-left:15px;
}

.settingsGroup{
  margin-left:20px;
  margin-right: 20px;
}
.settingsGroupOptions{
  margin-left:20px;
  margin-right: 20px;
}

.inputGroup{
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: auto;
  width: 100%;
}

select {
  outline:none;
  border:none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 5px;
  padding-left:10px;
}

.inputGroup label{
  font-size: x-small;
  padding-left: 25px;
}

header{
  display: flex;
  align-items: center;
  overflow: hidden;
}

header .title{
  flex:auto;
  display: flex;
  align-items: center;
}

header .title .text{
  margin-left: 15px;
}

@media only screen and (min-width: 500px ){
  .mobile{
    display: none !important;
  }
}
@media only screen and (max-width: 499px ){
  .notMobile{
    display: none !important;
  }
}

header .navigation{
  font-size:small;
  display: flex;
  align-content: flex-end;
  /*justify-content: end;*/
  align-items: center;
  height:2em;
}

header .menuButton{
  padding: 5px;
  border: none;
  background: none;
}

header .navigation .nav-item{
  margin-left:20px;
}

header .menuModal{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  position: fixed;
  box-sizing: border-box;
  padding: 10px 5px 5px 5px;
}
header .menuModal .header{
  display: flex;
  align-items: center;
  overflow: hidden;
}
header .menuModal .mobileNavigation{
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 20px 20px;
}
header .menuModal .mobileNavigation .nav-item {
  text-align: center;
  width: 100%;
}
header .menuModal .mobileNavigation .nav-item hr {
  margin: 20px;
}

header a{
  text-decoration: none;
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  padding: 0;
  margin: 0;
}

footer .footerBox{
  background-color: inherit;
  margin: auto;
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 1000px;
  padding: 0 5px;
}

footer .save{
  float: right;
  border:none;
  border-radius: 5px 5px 0 0;
  padding: 5px;
  font-size: smaller;
  text-decoration: none;
  cursor: pointer;
}

footer .options{
  float: left;
  border:none;
  border-radius: 5px 5px 0 0;
  padding: 5px;
  font-size: smaller;
  text-decoration: none;
  cursor: pointer;
}

footer .footerBox .menu{
  width: 200px;
  display: grid;
  grid-template-columns: auto;
}

footer .footerBox .menu .cell{
  display: flex;
}

footer .footerBox .menu .cellButton{
  flex: 1;
  font-size: 1.1em;
  border: none;
  cursor: pointer;
  text-align: left;
}

footer .footerBox .menu .hover:hover{
  background-color: rgba(8, 149, 182, 0.5)
}


.loginContainer{
  text-align: center;
  /*margin-top: 100px;*/
  /*margin-bottom: 30px;*/
    max-width: 300px;
    margin: 100px auto 30px auto;
}

.loginInput{
    width: 100%;
    padding: 8px;
    margin: 0 0 8px 0;
    box-sizing: border-box;
    border-radius: 3px;
}

.passwordStrengthContainer{
    height: 10px;
    background-color: grey;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid #747474;
}
.passwordFiller{
    height: 100%;
    transition: all 1s ease;
}
.fillernull{ width: 0; background-color: red;}
.filler0{ width: 5%; background-color: red;}
.filler1{ width: 25%; background-color: orange;}
.filler2{ width: 50%; background-color: yellow;}
.filler3{ width: 75%; background-color: greenyellow;}
.filler4{ width: 100%; background-color: green;}

.passwordHints{
    font-size: .8em;
    text-align: left;
}

.registerButton{
    padding: 5px;
    border-radius: 5px;
}

.link{
    text-decoration: none;
}

.splashIcon{
  margin: 50px auto 50px auto;
  max-width: 500px;
  text-align: center
}

.bookBar{
  box-sizing: border-box;
  margin-top: 5px;
  padding: 0px;
  width: 100%;
  height: 11px;
}
.bookBarProgress{
  width: 5%;
  height: 100%;
}

.dailyBar{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  margin-top: 5px;
  margin-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
  width: 100%;
  grid-column-gap: 2px;
}

.invisible{
    visibility: hidden;
}

@keyframes dailyBarPulse {
    0% {background-color: #0091B7;}
    50% {background-color: #005369; }
    100% {background-color: #0091B7;}
}

@keyframes dailyBarClose {
    0% {width: 100%;}
    100% {width: 0%;}
}

.dailyBarLoader{
    border-radius: 5px;
    position: absolute;
    height: 100%;
    width: 100%;
    /*right: 0;*/
    animation: dailyBarPulse 4s infinite;
    opacity: 1;
    /*background: #0091B7; !* you need this to specify a color to pulse to *!*/
}
.dailyBarLoader.fullyLoaded{
    animation: dailyBarPulse 4s infinite, dailyBarClose 1s ease;
    width: 0%;
    /*background: #0091B7; !* you need this to specify a color to pulse to *!*/
}

.future{
  pointer-events: none;
}

.currentDate{
  width:100%;
  text-align: center;
  font-size: large;
  font-weight: bold;
}

.singleDay{
  box-sizing: border-box;
  height: 32px;
  border-width: 1px;
  border-style: solid;
}

.multiDay{
  box-sizing: border-box;
  height: 32px;
  border-width: 1px;
  border-style: solid;
}

.singleDay.active{
  border-width: 2px !important;
}
.singleDay.today{
  border-width: 2px !important;
}

textarea{
  overflow-y:hidden;
}

.currentTextArea.commentary{
  font-style:italic;
}
.currentTextArea{
  width: 100%;
  border: 0;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize:none;
  font-size:large;
  font-family:Arial;
}

.currentTextArea:focus{
  outline:none;
}

.calendarYear{
  margin-top:25px;
  display: flex;
}
.calendarYear .calendarYearDiv{
  padding-top: 30px;
  max-width: 100px;
  min-width: 40px;
  width: 10%;
  font-weight: bold;
  font-style:italic;
}
.calendarYear .calendarMonthsDiv{
  flex: 1;
  font-style:italic;
}

.hidden{
  display: none !important;
}

.entry{
  white-space: pre-wrap;
  margin-bottom: 1em;
}

.photoBar{
  display: flex;
  flex-wrap: wrap;
}
.photoBar .singlePhoto{
  padding: 5px;
  /*flex: auto;*/
  height: 75px;
  text-align: center;
}
.photoBar .singleRowText{
  text-overflow: ellipsis;
  overflow: hidden;
}

.editingActive{
  background-color: rgba(5, 183, 3, 0.25)
}


.modal {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  /*width: 100%;*/
  /*height: 100%;*/
  z-index: 1000;
  display: flex;
  align-items: center;
}
.modal .backer{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .9;
  z-index: 0;
}

.modal .deleteButton{
  position: fixed;
  height: 50px;
  width: 50px;
  right: 10px;
  top: 10px;
  padding: 8px;
  fill: white;
  background-color: #e20000;
  border: none;
  border-radius: 40px;
  pointer-events: auto;
}

.modal .body {
  margin: auto;
  max-width: 80%;
  max-height: 80%;
  display: flex;
  z-index: 1001;
  /*overflow: auto;*/
  pointer-events: none;
  background-color: red;
  padding: 0;
  flex-wrap: wrap;
}
.modal .body .photo{
  flex: auto;
  min-width: 400px;
}
.modal .body .text{
  flex: auto;
  width: 200px;
  box-sizing: border-box;
}

.modal .expandedImage{
  height: 100%;
  width: 100%;
  object-fit: contain;
}


.modal .container{
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  max-width: 500px;
  pointer-events: auto;
  margin-bottom: 100px;
  margin-top: 50px;
}
.modal a{
  text-decoration: none;
}

.lockButton{
  border:none;
  background: none;
  margin-left: 20px;
}
