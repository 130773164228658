body.dark{
    color: white;
    fill: white;
    background-color: #242424;
}

.dark .backgroundColor{
    background-color: #242424;
}

.dark .accentColor{
    color: #0091B7;
    fill: #0091B7;
}

.dark .inputBorder{
    border-color: #707070;
}

.dark textarea{
    color: white;
    background-color: #242424;
}

.dark .singleDay{
    border-color: #707070;
}

.dark .singleDay.active{
    border-color: #05B703 !important;
}
.dark .singleDay.done{
    background-color: #0091B7;
}
.dark .singleDay.today{
    border-color: white;
}
.dark .singleDay.future{
    background-color: white;
    border-color:#d0d0d0;
}
.dark .singleDay.futureMonth{
    background-color: lightgrey;
    border-color:#d0d0d0;
}

.dark .hasEntry{
    background-color: #0091B7;
}
.dark .hasComment{
    background: linear-gradient(135deg, transparent 0%, transparent 50%, #005369 51%, #005369);
}
.dark .hasEntry.hasComment{
    background: linear-gradient(135deg, #0091B7 0%, #0091B7 50%, #005369 51%, #005369);
}

.dark .graphicColor1{
    fill: #0091B7;
}

.dark .graphicColor2{
    fill: #005369;
}

.dark .error{
    color: red;
}

.dark .message{
    color: #4682b4;
}